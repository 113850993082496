import { combineReducers } from 'redux';

import { authentication } from './admin/authentication.reducer';
import { alert } from './admin/alert.reducer';
import { users } from './admin/users.reducer';
import { theme } from './admin/theme.reducer';
import { filters } from './admin/filters.reducer';


const rootReducer = combineReducers({
  authentication,
  alert,
  users,
  theme,
  filters,
});

export default rootReducer;